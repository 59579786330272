import { getVentasPendientes } from "../views/Dashboard/components/shared/services/ventas";
import { asyncMac, makeAsyncTypes } from "./utils";

const asyncVentasPendientes = makeAsyncTypes('ventas-pendientes');

const [setPending, setFullFilled, setError ] = asyncMac(asyncVentasPendientes);

export const ventasPendientesReducer = (state = null, action) => {
    switch (action.type) {
        case 'ventas-pendientes/fullfilled':
            return action.payload;
        default:
            return state;
    }
};
export const fetchVentasPendientes = () => async dispatch => {
    dispatch(setPending());
    try {
        const response = await getVentasPendientes()
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

