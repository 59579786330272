import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function UserForm({
    user,
    onSubmitUser
}) {
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: '',
            username: '',
            email: '',
            password: '',
            telefono: '',
            active: 1,
            rol_id: 1,
        }
    });
    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });

    useEffect(() => {
        if (user) {
            reset({ ...user, password: '' });
        } else reset({
            id: '',
            username: '',
            email: '',
            password: '',
            telefono: '',
            active: 1,
            rol_id: 1,
        });
    }, [user]);

    const handleChangeRol = (e) => { setValue('rol_id', parseInt(e.target.value)); }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if (data.password === '') delete data.password;
        onSubmitUser(data);
    }

    return (
        <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <input
                type="hidden"
                {...register("id")}
                defaultValue={user ? user.id : ''} />

            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        {...register("username", { required: true, maxLength: 50 })}
                        defaultValue={user ? user.username : ''} />

                    <div className="form-invalid">
                        {errors.username?.type === 'required' && "Username es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        {...register("email", { required: true, maxLength: 255 })}
                        defaultValue={user ? user.email : ''} />

                    <div className="form-invalid">
                        {errors.email?.type === 'required' && "Email es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="email">Nueva Contraseña</label>
                    <input
                        type={"password"}
                        className="form-control"
                        {...register("password")}
                        defaultValue={""} />
                </div>
                <div className="form-group">
                    <label htmlFor="rol_id">Rol</label>
                    <select
                        {...register("rol_id")}
                        defaultValue={user ? user.rol_id : 1}
                        onChange={handleChangeRol}>
                        {roles?.map(op => <option key={'roles-' + op.id} value={op.id}>{op.nombre}</option>)}
                    </select>

                    <div className="form-invalid">
                        {errors.rol?.type === 'required' && "Seleccionar un rol es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="telefono">Telefono (opcional):</label>
                    <input
                        type="text"
                        className="form-control"
                        {...register("telefono")}
                        defaultValue={user ? user.telefono : ''} />
                </div>
            </div>

            <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
        </form>
    )
}