import { useState, useEffect } from "react";
import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProveedorForm from "./components/ProveedorForm";
import DescuentoForm from "./components/DescuentoForm";
import { useDispatch } from "react-redux";
import { removeAlmacen, removeDescuento, removeTransportista, removeVacaciones, removeVale } from "../../../../../../redux/features/currentProveedor";
import TransportistasForm from "./components/TransportistasForm";
import InfoModal from "../InfoModal";
import AlmacenForm from "./components/AlmacenForm";
import ProveedorItemsList from "./components/ProveedorItemsList";
import VacacionesForm from "./components/VacacionesForm";
import { getVale } from "../../services/proveedores";
import { toast } from "react-toastify";
import ValeForm from "./components/ValeForm";

export default function ProveedorModal({
    open,
    proveedor,
    isEdit,
    fromPresupuesto,
    onSubmitProveedor,
    onSubmitAction,
    onClose,
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const [tab, setTab] = useState('proveedor');
    const [currentDescuento, setCurrentDescuento] = useState(null);
    const [currentTransportistaFav, setCurrentTransportistaFav] = useState(null);
    const [currentAlmacen, setCurrentAlmacen] = useState(null);
    const [currentVacaciones, setCurrentVacaciones] = useState(null);
    const [currentVale, setCurrentVale] = useState(null);
    const [isEditDescuento, setIsEditDescuento] = useState(false);
    const [isNewDescuento, setIsNewDescuento] = useState(false);
    const [isEditTransportista, setIsEditTransportista] = useState(false);
    const [isNewTransportista, setIsNewTransportista] = useState(false);
    const [isEditAlmacen, setIsEditAlmacen] = useState(false);
    const [isNewAlmacen, setIsNewAlmacen] = useState(false);
    const [isEditVacaciones, setIsEditVacaciones] = useState(false);
    const [isNewVacaciones, setIsNewVacaciones] = useState(false);
    const [isEditVales, setIsEditVales] = useState(false);
    const [isNewVales, setIsNewVales] = useState(false);

    useEffect(() => {
        
    }, [proveedor, isEdit, fromPresupuesto]);

    const handleCloseModal = (event, reason) => { 
        if (reason && reason === "backdropClick") return;
        onClose(); 
    }
    const handleChangeTab = (e, tab) => { setTab(tab); }
    const handleSubmitProveedor = (data) => { 
        onSubmitProveedor(data); 

        if(proveedor) onClose();
    }
    const handleActionDoneOnProveedor = () => { 
        handleCancelDescuento(); 
        handleCancelTransportista(); 
        handleCancelAlmacen();
        handleCancelVacaciones();
        handleCancelVales();

        if(fromPresupuesto) { onSubmitAction(); }
    }

    const handleNewDescuento = () => { 
        setCurrentDescuento(null);
        setIsNewDescuento(true); 
        setIsEditDescuento(false); 
    }
    const handleEditDescuento = (descuento) => {
        setCurrentDescuento(descuento);
        setIsEditDescuento(true);
        setIsNewDescuento(false);
    }

    const handleRemoveDescuento = async(descuentoId) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(removeDescuento(descuentoId));

        if(fromPresupuesto) { handleActionDoneOnProveedor(); }
    }
    const handleCancelDescuento = () => {
        setIsEditDescuento(false); 
        setIsNewDescuento(false);
        setCurrentDescuento(null);
    }

    const handleNewTransportista = () => { 
        setCurrentTransportistaFav(null);
        setIsNewTransportista(true); 
        setIsEditTransportista(false); 
    }
    const handleEditTransportista = (transportista) => {
        setCurrentTransportistaFav(transportista);
        setIsEditTransportista(true);
        setIsNewTransportista(false);
    }
    const handleRemoveTransportista = async(transportistaId) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(removeTransportista(transportistaId));

        if(fromPresupuesto) { handleActionDoneOnProveedor(); }
    }
    const handleCancelTransportista = () => {
        setIsEditTransportista(false); 
        setIsNewTransportista(false);
        setCurrentTransportistaFav(null);
    }

    const handleNewAlmacen = () => { 
        setCurrentAlmacen(null);
        setIsNewAlmacen(true); 
        setIsEditAlmacen(false); 
    }
    const handleEditAlmacen = (almacen) => {
        setCurrentAlmacen(almacen);
        setIsEditAlmacen(true);
        setIsNewAlmacen(false);
    }
    const handleRemoveAlmacen = async(almacenId) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(removeAlmacen(almacenId));

        if(fromPresupuesto) { handleActionDoneOnProveedor(); }
    }
    const handleCancelAlmacen = () => {
        setIsEditAlmacen(false);
        setIsNewAlmacen(false);
        setCurrentAlmacen(null);
    }

    const handleNewVacaciones = () => { 
        setCurrentVacaciones(null);
        setIsNewVacaciones(true); 
        setIsEditVacaciones(false); 
    }
    const handleEditVacaciones = (vacaciones) => {
        setCurrentVacaciones(vacaciones);
        setIsEditVacaciones(true);
        setIsNewVacaciones(false);
    }
    const handleRemoveVacaciones = async(vacacionesId) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(removeVacaciones(vacacionesId));

        if(fromPresupuesto) { handleActionDoneOnProveedor(); }
    }
    const handleCancelVacaciones = () => {
        setIsEditVacaciones(false);
        setIsNewVacaciones(false);
        setCurrentVacaciones(null);
    }

    const handleNewVale = () => { 
        setCurrentVale(null);
        setIsNewVales(true); 
        setIsEditVales(false); 
    }
    const handleEditVale = (vacaciones) => {
        setCurrentVale(vacaciones);
        setIsEditVales(true);
        setIsNewVales(false);
    }
    const handleRemoveVale = async(vacacionesId) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(removeVale(vacacionesId));

        if(fromPresupuesto) { handleActionDoneOnProveedor(); }
    }
    const handleCancelVales = () => {
        setIsNewVales(false);
        setIsEditVales(false);
        setCurrentVale(null);
    }

    const handleDownload = async(vale) => {
        dispatch({type: 'loading/set', payload: true});
        const exportResult = await getVale(vale.id);

        if(exportResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Vale descargado correctamente!')
            
            const url = window.URL.createObjectURL(new Blob([exportResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', vale.foto);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="proveedor-form-title"
                aria-describedby="proveedor-form-description">
                <DialogTitle id="proveedor-form-title">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <p className="fw-bold text-uppercase">{isEdit ? 'Editar' : 'Nuevo'} Proveedor</p>

                        <span title="cerrar" className="action" onClick={() => handleCloseModal()}>
                            <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                        </span>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="Cliente">
                            <Tab label="Proveedor" value="proveedor" />
                            { (user?.rol_id === 1 || user.responsable) &&
                                <Tab label="Descuentos" value="descuentos" />
                            }
                            <Tab label="Vales" value="vales" />
                            <Tab label="Vacaciones" value="vacaciones" />
                            <Tab label="Transportistas" value="transportistas" />
                            <Tab label="Almacenes" value="almacenes" />
                        </TabList>
                        </Box>
                        <TabPanel value="proveedor">
                            <ProveedorForm 
                                isEdit={isEdit}
                                proveedor={proveedor}
                                onSubmitProveedor={handleSubmitProveedor} />
                        </TabPanel>
                        <TabPanel value="descuentos">
                            <div className="w-100">
                                <ProveedorItemsList 
                                    proveedor={proveedor}
                                    item={'descuentos'}
                                    columnas={['min', 'max', 'descuento', 'tipo_piezas']}
                                    onNew={handleNewDescuento}
                                    onEdit={handleEditDescuento}
                                    onDelete={handleRemoveDescuento}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="vales">
                            <div className="w-100">
                                <ProveedorItemsList 
                                    proveedor={proveedor}
                                    item={'vales'}
                                    columnas={['fecha', 'total', 'gastado', 'terminado', 'active']}
                                    onNew={handleNewVale}
                                    onEdit={handleEditVale}
                                    onDelete={handleRemoveVale}
                                    onDownload={handleDownload}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="vacaciones">
                            <div className="w-100">
                                <ProveedorItemsList
                                    proveedor={proveedor}
                                    item={'vacaciones'}
                                    columnas={['fecha_inicio', 'fecha_fin']}
                                    onNew={handleNewVacaciones}
                                    onEdit={handleEditVacaciones}
                                    onDelete={handleRemoveVacaciones}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="transportistas">
                            <div className="w-100">
                                <ProveedorItemsList
                                    proveedor={proveedor}
                                    item={'transportistas'}
                                    columnas={['transportista', 'tipo', 'hora_recogida']}
                                    onNew={handleNewTransportista}
                                    onEdit={handleEditTransportista}
                                    onDelete={handleRemoveTransportista}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value="almacenes">
                            <div className="w-100">
                                <ProveedorItemsList 
                                    proveedor={proveedor}
                                    item={'almacenes'}
                                    columnas={['nombre', 'telefono', 'direccion', 'poblacion', 'pais', 'tipo_piezas']}
                                    onNew={handleNewAlmacen}
                                    onEdit={handleEditAlmacen}
                                    onDelete={handleRemoveAlmacen}
                                />
                            </div>
                        </TabPanel>
                    </TabContext>
                </DialogContent>
            </Dialog>


            { ((isNewDescuento || isEditDescuento) && proveedor) &&
                <InfoModal
                    state={(isNewDescuento || isEditDescuento) ? true : false}
                    title={(isEditDescuento ? 'Editar' : 'Nuevo') + ' descuento'}
                    content={
                        <DescuentoForm 
                            descuento={currentDescuento}
                            proveedorId={proveedor?.id}
                            isEdit={isEditDescuento}
                            onActionDone={handleActionDoneOnProveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCancelDescuento}
                ></InfoModal>
            }

            { ((isNewTransportista || isEditTransportista) && proveedor) && 
                <InfoModal
                    state={isNewTransportista || isEditTransportista}
                    title={(isEditTransportista ? 'Editar' : 'Nuevo') + ' transportista'}
                    content={
                        <TransportistasForm 
                            transportistaFav={currentTransportistaFav}
                            proveedorId={proveedor?.id}
                            isEdit={isEditTransportista}
                            onActionDone={handleActionDoneOnProveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCancelTransportista}
                ></InfoModal>
            }

            { ((isNewAlmacen || isEditAlmacen) && proveedor) && 
                <InfoModal
                    state={(isNewAlmacen || isEditAlmacen) ? true : false}
                    title={(isEditAlmacen ? 'Editar' : 'Nuevo') + ' almacén'}
                    content={
                        <AlmacenForm
                            almacen={currentAlmacen}
                            proveedorId={proveedor?.id}
                            isEdit={isEditAlmacen}
                            onActionDone={handleActionDoneOnProveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCancelAlmacen}
                ></InfoModal>
            }

            { ((isNewVacaciones || isEditVacaciones) && proveedor) &&
                <InfoModal
                    state={(isNewVacaciones || isEditVacaciones) ? true : false}
                    title={(isEditVacaciones ? 'Editar' : 'Nuevas') + ' vacaciones'}
                    content={
                        <VacacionesForm 
                            vacaciones={currentVacaciones}
                            proveedorId={proveedor?.id}
                            isEdit={isEditVacaciones}
                            onActionDone={handleActionDoneOnProveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCancelVacaciones}
                ></InfoModal>
            }

            { ((isNewVales || isEditVales) && proveedor) &&
                <InfoModal
                    state={(isNewVales || isEditVales) ? true : false}
                    title={(isEditVales ? 'Editar' : 'Nuevo') + ' vale'}
                    content={
                        <ValeForm 
                            vale={currentVale}
                            proveedorId={proveedor?.id}
                            isEdit={isEditVales}
                            onActionDone={handleActionDoneOnProveedor}
                        />
                    }
                    width={'sm'}
                    onClose={handleCancelVales}
                ></InfoModal>
            }
        </>
    )
}