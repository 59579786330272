import { faInfoCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import EXPRESSWAY from '../../../../../../../images/expressway.png';
import CLIENTE from '../../../../../../../images/cliente.png';
import PROVEEDOR from '../../../../../../../images/proveedor.png';
import SEUR from '../../../../../../../images/shippingServices/seur.png';
import MRW from '../../../../../../../images/shippingServices/mrw.png';
import TXT from '../../../../../../../images/shippingServices/TXT.png';
import DHL from '../../../../../../../images/shippingServices/dhl.png';
import SCHENKER from '../../../../../../../images/shippingServices/schenker.png';
import { CustomTooltip } from "../../../helpers/functionalities";


export default function ServiceRow({
   option,
   selectedOption,
   i,
   id,
   isVoluminoso,
   destino,
   precioEnvioBaleares,
   onChangePrecioEnvio,
   onSelectServicio
}) {

    useEffect(() => {
       
    }, []);

    // DEJAMOS RECOMENDADO DESHABILITADO
    // (option.recomendado ? 'recomendado-row ' : '') +

    /**
     * <td className="align-middle text-center" width="10%">
                { option.recomendado ?
                    <span className="recomendado">recomendado</span>
                    :  ''
                }
            </td>
     */

    return (
        <tr key={'servicio-envio-' + id + '-' + i} className={'w-100 ' + ((i === selectedOption ? 'highlighted' : ''))}>
            <td>
                { option.transportista_id === 1 ?
                    <>
                        { (option.servicio_envio_id === 57) ?
                            <img src={CLIENTE} style={{height: '25px'}} alt="Transportista" />
                            :
                            <img src={PROVEEDOR} style={{height: '25px'}} alt="Transportista" />
                        }
                    </>
                    : option.transportista_id === 2 ?
                    <img src={EXPRESSWAY} style={{height: '25px'}} alt="Transportista" />
                    : option.transportista_id === 12 ?
                    <img src={SEUR} style={{height: '25px'}} alt="Transportista" />
                    : option.transportista_id === 17 || option.transportista_id === 21 ?
                    <img src={MRW} style={{height: '25px'}} alt="Transportista" />
                    : option.transportista_id === 19 ?
                    <img src={TXT} style={{height: '25px'}} alt="Transportista" />
                    : option.transportista_id === 18 ?
                    <img src={option.img} style={{height: '67px', width: '102px'}} alt="Logistica" />
                    : option.transportista_id === 22 ?
                    <img src={DHL} style={{height: '25px'}} alt="DHL" />
                    : option.transportista_id === 28 ?
                    <img src={SCHENKER} style={{height: '25px'}} alt="Schenker" />
                    :
                    <img src={option.img} style={{height: '67px', width: '102px'}} alt="Transportista" />
                }
            </td>
            <td className="align-middle">
                <div className="w-100 d-flex align-items-center">
                    { (option.informacion) &&
                        <CustomTooltip
                            title={
                                <React.Fragment>
                                    <p style={{whiteSpace: 'pre-line'}}>{option.informacion}</p>
                                </React.Fragment>
                            }
                        >
                            <span style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                            </span>
                        </CustomTooltip>
                    }

                    <div className="d-flex flex-grow-1 flex-column ms-2">
                        <p className="m-0">
                            {option.nombre_transportista} {option.nombre_servicio}
                        </p>
                        <p className="m-0">
                            <FontAwesomeIcon icon={faPrint} size="1x" className="action me-2" color={option.etiqueta ? "#215732" : '#9e9b9b'} />  
                            <small className={(!option.etiqueta) ? 'text-default' : '' }>{option.etiqueta ? 'Requiere etiqueta' : 'No requiere etiqueta'}</small>
                        </p>
                    </div>
                </div>
            </td>
            <td className="align-middle">
                <div className="w-100 d-flex flex-column align-items-center">
                    {(isVoluminoso && option.transportista_id === 1) ?
                        <>
                            { destino && !destino.provincia.includes('Baleares') ?
                                <>
                                    <b>{option.precio_transporte}€</b>
                                    <small>Sólo Península</small>
                                </>
                                : 
                                <input 
                                    type="number" 
                                    step="0.01"
                                    className="form-control" 
                                    min={0}
                                    onChange={(e) => onChangePrecioEnvio(e)}
                                    defaultValue={precioEnvioBaleares} />
                            }
                        </>
                        : <b>{option.precio_transporte}€</b>
                    }
                </div>
            </td>
            <td width="15%" className="text-end align-middle">
                <button className="btn btn-primary btn-sm" type="button" onClick={() => onSelectServicio(option, i)}>SELECCIONAR</button>
            </td>
        </tr>            
     )
}