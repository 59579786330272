import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useForm } from "react-hook-form";

export default function Searcher({
    isLoading,
    onSearch
 }) {
    const { register, formState: { errors }, handleSubmit: handleSubmitTelf } = useForm();
    
    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSearch(data);
    };

    return (
        <div className="w-100 d-flex justify-content-center mt-3">
            <div className="col-12 col-md-6 col-lg-4">
                <form className="d-flex align-items-start" onSubmit={handleSubmitTelf(onSubmit)}>
                    <div className="flex-fill mb-0">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Teléfono | CIF | Email"
                            {...register("buscador", { required: true })} 
                            defaultValue='' 
                        />

                        <div className="form-invalid">
                            {errors.buscador?.type === 'required' && "El campo es obligatorio"}
                        </div>
                    </div>

                    <button 
                        type="submit" 
                        className="flex-fill d-block btn btn-primary ms-2" 
                        style={{maxHeight: "43px", maxWidth: '147px', width: '100%'}}
                        disabled={isLoading}
                    >
                        { (isLoading) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                            :
                            'Comprobar'
                        }
                    </button>
                </form>
            </div>
        </div>
    );
}