import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function FinanzasModal({ 
    incidencia,
    state,
    onConfirmAction,
    onClose
}) {
    const administracion = useSelector(state => {
        return state.administracion
    });
    const [open, setOpen] = useState(state);
    const reportSchema = yup.object({
        crafted_by: yup.number().integer().moreThan(0).required(),
    }).required();

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(reportSchema),
        defaultValues: {
            id: incidencia.id,
            crafted_by: -1,
        }
    });

    const handleClose = (event, reason) => { 
        if (reason && reason === "backdropClick") return;
        
        setOpen(false); 
        onClose();
    };

    const handleResponsableIncidencias = (e) => { 
        const value = parseInt(e.target.value);
        setValue('crafted_by', value);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        onConfirmAction(data);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">¿Pasar incidencia {incidencia?.numero} a finanzas?</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>

                <div className="w-100 mt-3">
                    <form className="form mb-4" onSubmit={handleSubmit(onSubmit)}>
                        <input 
                            type="hidden" 
                            {...register("id")} 
                            defaultValue={incidencia.id} />

                        <div className="form-group m-0 me-0 me-md-3">
                            <label>Asignar a:</label>
                            <select 
                                id="creado_por"
                                defaultValue={-1}
                                {...register("crafted_by", { required: true })}
                                onChange={handleResponsableIncidencias}>
                                <option key={'incidencias-' + 0} value={-1}>Selecciona una opción</option>
                                {administracion?.map(op => <option key={'incidencias-' + op.id} value={op.id}>{op.username}</option>)}
                            </select>

                            <div className="form-invalid">
                                {errors.crafted_by && 'Selecciona un usuario válido'}
                            </div>
                        </div>

                        <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}