import { useState, useEffect } from "react";
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCheck, faCommentDots, faGift, faTimes, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Pagination from '@mui/material/Pagination';
import { useForm } from "react-hook-form";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function EntityDetalleTable({
    id, 
    tipo,
    currPage,
    detalles,
    onOpenContactos, 
    onOpenProximoContacto, 
    onOpenAddContacto,
    onOpenAddObservaciones,
    onEditEntidadDetalle,
    onPageChange,
}) {
    const [isEdit, setIsEdit] = useState(false);
    const [currItem, setCurrItem] = useState(null);
    const [currIndex, setCurrIndex] = useState(null);
    const { 
      register, 
      formState: { errors }, 
      handleSubmit, 
      setValue,
      reset
    } = useForm({
          mode: "onChange",
          defaultValues: {
              nombre: '',
              telefono: '',
              email: '',
              persona_contacto: ''
          }
  });
 
    useEffect(() => {
    }, [tipo]);

    const handleChangePage = (event, newPage) => {
      onPageChange(newPage, id);
    }

    const getPaginationCount = () => {
      var totalPages = detalles.total_items < 15 ? 1 : Math.ceil(detalles.total_items / 15);
      return parseInt(totalPages);
    }

    const handleAction = (action, detalle, index) => {
      if(action === 'edit') {
        handleEdit(detalle, index);
      } else if(action === 'changeNextContactDate') {
        onOpenProximoContacto(detalle);
      } else if(action === 'addContact') {
        onOpenAddContacto(detalle);
      } else if(action === 'addObservation') {
        onOpenAddObservaciones(detalle);
      }
    }

    const handleEdit = (item, index) => {
      setIsEdit(true);
      setCurrItem(item);
      setCurrIndex(index);
      reset({
        id: item.id,
        nombre: item.entidad.nombre,
        telefono: item.entidad.telefono,
        email: item.entidad.email,
        persona_contacto: item.persona_contacto,
      });
    }
    const cancelEdit = () => {
      setIsEdit(false);
      setCurrItem(null);
      setCurrIndex(null);
      reset();
    }

    const handleChange = (e, attr) => { setValue(attr, e.target.value); }

    const onSubmit = async (data, e) => {
      e && e.preventDefault();
      onEditEntidadDetalle(data);
    };

    return (
      <div className="w-100 d-flex flex-column align-items-end">
        <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>nombre</th>
                  <th>contacto</th>
                  <th>teléfono</th>
                  <th>email</th>
                  {(id === 'cliente' || id === 'cliente-grande') && <th className="text-center">cif</th>}
                  {(id === 'cliente' || id === 'cliente-grande') && <th className="text-center">creado</th>}
                  <th className="text-center">último contacto</th>
                  <th className="text-center">observaciones</th>
                  <th className="text-center">próximo contacto</th>
                  <th className="text-center">volver a contactar</th>
                  {(id === 'cliente' || id === 'cliente-grande' || id === 'lead') && <th className="text-center">último presupuesto</th>}
                  {(id === 'cliente' || id === 'cliente-grande') && <th className="text-center">última compra</th>}
                  <th className="text-center">resp.</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { detalles?.items?.map((detalle, i) => {
                  return (
                      <tr key={detalle.id}>
                        <td width={'3%'} className="text-center">
                          {(detalle.regalo) ? <FontAwesomeIcon icon={faGift} size="1x" className="action" color="#9e9b9b" /> : ''}
                          <span title="Ver contactos" style={{cursor: 'pointer'}} onClick={() => onOpenContactos(detalle)}>
                            <FontAwesomeIcon icon={faCommentDots} size="1x" className="action" color="#00986C" />
                          </span> 
                          { detalle?.ecommerce == true ? 
                          <span className="d-block mt-1">
                            <FontAwesomeIcon icon={faCartShopping} size="1x" className="action" color="#215732" />
                          </span> 
                          : null
                          }
                        </td>
                        <td width={'20%'}>
                          { isEdit && currIndex === i && currItem.id === detalle.id ?
                            <>
                              <input 
                                type="hidden" 
                                {...register("id")} 
                                defaultValue={detalle?.id || ''}
                              />

                              <input 
                                type="text" 
                                className="form-control" 
                                {...register("nombre")} 
                                defaultValue={detalle?.entidad.nombre || ''}  
                                onChange={(e) => handleChange(e, 'nombre')}
                              />
                            </>
                            :
                            (detalle.entidad.categoria !== 'pendiente' && detalle.entidad.categoria !== 'rf' && detalle.entidad) ?
                            <Link 
                              to={"/cliente/ficha/" + detalle.entidad.id} 
                              className={"text-uppercase " + (detalle.no_llamar ? 'text-danger' : tipo === 'no-piden' ? 'text-warning' : 'text-green')} 
                              title="Ficha Cliente">
                                {detalle.entidad.nombre ? detalle.entidad.nombre : 'Sin nombre'}
                            </Link>
                            : <span 
                                className={"text-uppercase " + (detalle.no_llamar ? 'text-danger' : tipo === 'no-piden' ? 'text-warning' : '')}>
                                {detalle.entidad.nombre ? detalle.entidad.nombre : 'Sin nombre'}
                              </span>
                          }
                        </td>
                        <td width={'10%'}>
                          { isEdit && currIndex === i && currItem.id === detalle.id ?
                            <input 
                              type="text" 
                              className="form-control" 
                              {...register("persona_contacto")} 
                              defaultValue={detalle?.persona_contacto || ''}  
                              onChange={(e) => handleChange(e, 'persona_contacto')}
                            />
                            : detalle?.persona_contacto
                          }
                        </td>
                        <td width={'7%'}>
                          { isEdit && currIndex === i && currItem.id === detalle.id ?
                            <input 
                              type="text" 
                              className="form-control" 
                              {...register("telefono")} 
                              defaultValue={detalle.entidad.telefono || ''}  
                              onChange={(e) => handleChange(e, 'telefono')}
                            />
                            : detalle.entidad.telefono
                          }
                        </td>
                        <td width={'15%'}>
                          { (!detalle?.entidad.email || detalle?.entidad.email === "") ?
                            <>
                            { isEdit && currIndex === i && currItem.id === detalle.id ?
                              <>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  {...register("email")} 
                                  defaultValue={detalle?.entidad.email || ''}  
                                  onChange={(e) => handleChange(e, 'email')}
                                />
                              </>
                              :
                              detalle.entidad.email
                            }
                            </>
                            : detalle.entidad.email
                          }
                        </td>
                        {(id === 'cliente' || id === 'cliente-grande') && <td className="text-center" width={'7%'}>{detalle.entidad.cif}</td> }
                        {(id === 'cliente' || id === 'cliente-grande') && <td className="text-center">{detalle?.cliente_creado ? moment(detalle.cliente_creado).format('DD/MM/YYYY') : ''}</td>}
                        <td className="text-center">{detalle?.ultimo_contacto ? moment(detalle.ultimo_contacto).format('DD/MM/YYYY') : '-'}</td>
                        <td className="text-center text-uppercase">{detalle?.ultimas_observaciones ? detalle.ultimas_observaciones : '-'}</td>
                        <td className="text-center">{detalle?.proximo_contacto ? moment(detalle.proximo_contacto).format('DD/MM/YYYY') : '-'}</td>
                        <td width={'7%'} className="text-center">
                          { detalle.volver_contactar === 1 && detalle.proximo_contacto !== null ?
                              <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                            : detalle.volver_contactar === 0 && detalle.proximo_contacto === null ?
                              <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                            : '?'
                          }
                        </td>
                        
                        {(id === 'cliente' || id === 'cliente-grande' || id === 'lead') && <td className="text-center">{detalle?.ultimo_presupuesto ? moment(detalle.ultimo_presupuesto).format('DD/MM/YYYY') : '-'}</td>}
                        {(id === 'cliente' || id === 'cliente-grande') && <td className="text-center">{detalle?.ultima_venta ? moment(detalle.ultima_venta).format('DD/MM/YYYY') : '-'}</td>}
                        <td width={'5%'} className="text-center text-uppercase">{detalle?.owner_id ? detalle?.owner?.username : '-'}</td>
                        <td className="text-center">
                          <div className="actions">
                            { ((currIndex !== i && currItem?.id !== detalle?.id)) &&
                              <ActionsMenu 
                                  options={getActions(currIndex !== i && currItem?.id !== detalle?.id)}
                                  onAction={(action) => handleAction(action, detalle, i)}
                              />
                            }
                            { (isEdit && currIndex === i && currItem.id === detalle.id) &&
                              <div className="d-flex gap-3">
                                <button type="submit" className="link">
                                  <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#00986C" />
                                </button>

                                <span title="Cancelar" onClick={() => cancelEdit()}>
                                  <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                </span>
                              </div>
                            }
                          </div>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </table>
          </div>
        </form>

          <Pagination 
            id={id}
            className="mt-3" 
            count={getPaginationCount()} 
            page={currPage} 
            onChange={handleChangePage} 
          />
        </div>
    )
}