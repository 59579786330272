import { useState, useEffect } from "react";
import React from "react";
import { comprobarEntidades } from "../../../../../../../../../shared/services/clientes";
import Searcher from "./components/Searcher";
import ResultsList from "./components/ResultsList";
import InfoModal from "../../../../../../../../../shared/components/InfoModal";

export default function ClientesSearcher({
    refresh,
    onResult,
    onSetTelefono
}) {
    const [clientes, setClientes ] = useState(null);
    const [currentCliente, setCurrentCliente] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(refresh) setIsLoading(false);
    }, [refresh]);
    
    const handleSearch = async (data) => {
        setIsLoading(true);
        const response = await comprobarEntidades(data);
        
        if(response.data !== null && response.data.length > 0) {
            setIsLoading(false);
            setClientes(response.data);

            if(response.data.length === 1) {
                if(!response.data[0].bloqueado) {
                    if(handleCheckCliente(response.data[0])) {
                        setCurrentCliente(response.data[0]);
                        setShowWarningModal(true);
                    } else onResult(response.data[0]);
                } else onResult(response.data[0]);
            }
        } else onResult(null);

        if(!isNaN(data.buscador)) onSetTelefono(data.buscador);
    };

    const handleCheckCliente = (cliente) => {
        const {poblacion_facturacion, codigo_postal_facturacion, area_facturacion} = cliente;

        if(poblacion_facturacion && codigo_postal_facturacion && area_facturacion) {
            if(area_facturacion.includes('Baleares')) {
                return true
            } return false
        } else return false;
    }

    const handleSelectedResult = (result) => {
        if(handleCheckCliente(result)) {
            setCurrentCliente(result);
            setShowWarningModal(true);
        } else onResult(result);
    }

    const handleSetCliente = async () => { 
        onResult(currentCliente); 
    };

    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <Searcher 
                isLoading={isLoading}
                onSearch={handleSearch} 
            />

            <ResultsList 
                clientes={clientes}
                onClientSelected={handleSelectedResult}
            />

            { showWarningModal && 
                <InfoModal
                    onClose={handleSetCliente} 
                    title={'Atención! Cliente de Baleares'} 
                    content={'Recuerda que el envío es más caro.'}
                    state={showWarningModal}>
                </InfoModal>
            }
        </div>
    );
}