import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default function PresupuestoForm({
    presupuestoId,
    esCredito, 
    codigo, 
    presupuesto,
    observaciones,
    bastidor,
    link_catalogo,
    isLoading,
    onSubmit
}) {
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
        defaultValues: {
            bastidor: bastidor || "",
            ficha_tecnica: '',
            link_catalogo: link_catalogo || '',
            codigo_autorizacion: codigo || "",
            observaciones: observaciones || ""
        }
    });
    const [isDisabledCreateNew, setIsDisabledCreateNew] = useState(true);

    useEffect(() => {
        checkIfValidPresupuesto(presupuesto);
    }, [codigo, bastidor,link_catalogo, presupuesto, presupuestoId]);

    const checkIfValidPresupuesto = (presu) => {
        if(presupuestoId > 0) {
            if(!presu.es_venta) {
                checkPresupuesto(presu);
            } else setIsDisabledCreateNew(false);
        } else {
            checkPresupuesto(presu);
        }
    }

    const checkPresupuesto = (presu) => {
        if(presu.piezas?.length === 0
            || presu.piezas?.find(p => p.servicio_envio === null || p.servicio_envio === undefined)
            || getValues('bastidor') === ""
        ) setIsDisabledCreateNew(true);
        else setIsDisabledCreateNew(false);
    }

    const handleChangeObservaciones = (e) => {
        const value = e.target.value;
        setValue('observaciones', value);
    }

    const handleChangeBastidor = (e) => {
        const value = e.target.value;
        setValue('bastidor', value);
        checkIfValidPresupuesto(presupuesto);
    }

    const handleNoFacilita = () => {
        setValue('bastidor', 'No facilita');
        checkIfValidPresupuesto(presupuesto);
    }

    const handleChangeLink = (e) => {
        const value = e.target.value;
        setValue('link_catalogo', value);
        checkIfValidPresupuesto(presupuesto);
    }

    const handleSubmitForm = (data, e) => {
        e.preventDefault();
        onSubmit({
            id: presupuestoId,
            codigo_autorizacion:  data.codigo_autorizacion !== '' ? data.codigo_autorizacion : null,
            observaciones:  data.observaciones !== '' ? data.observaciones : null,
            bastidor: data.bastidor,
            link_catalogo: data.link_catalogo !== '' ? data.link_catalogo : null
        });
    }

    return (
        <form className="form col-12 d-flex flex-column align-items-center" onSubmit={handleSubmit(handleSubmitForm)}>
            { (esCredito && presupuesto?.cliente?.con_codigo) ?
                <div className="form-group">
                    <label htmlFor="codigo_autorizacion">Código de autorización y/o matrícula:</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        maxLength={50}
                        {...register("codigo_autorizacion", { maxLength: 50 })} 
                        defaultValue={codigo ? codigo : ''} />
                </div>
                : ''
            }

            <div className="w-100 d-flex align-items-center mb-4">
                <div className="flex-grow-1 d-flex flex-column align-items-stretch">
                    <label htmlFor="observaciones">Bastidor del vehículo:</label>
                    <input
                        type="text"
                        className="w-100"
                        {...register("bastidor", {required: true})}
                        onChange={(e) => handleChangeBastidor(e)}
                        defaultValue={bastidor ? bastidor : ""}
                    />
                </div>

                <button type="button" className="btn btn-warning ms-3 mt-3" onClick={() => handleNoFacilita()}>No facilita</button>
            </div>

            <div className="w-100 d-flex align-items-center mb-4">
                <div className="flex-grow-1 d-flex flex-column align-items-stretch">
                    <label htmlFor="link_catalogo">Enlace catálogo de pieza:</label>
                    <input
                        type="text"
                        className="w-100"
                        {...register("link_catalogo")}
                        onChange={(e) => handleChangeLink(e)}
                        defaultValue={link_catalogo ? link_catalogo : ""}
                    />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="observaciones">Observaciones internas:</label>
                <textarea
                    {...register("observaciones")}
                    onChange={(e) => handleChangeObservaciones(e)}
                    defaultValue={observaciones ? observaciones : ""}
                ></textarea>
            </div>
            
            {presupuesto?.observaciones_cliente && <div className="form-group">
                <label htmlFor="observaciones">Observaciones cliente:</label>
                <textarea value={presupuesto.observaciones_cliente} disabled />
            </div>}

            <div className="w-100">
                { (presupuestoId === 0 && isDisabledCreateNew) &&
                    <div className="alert alert-danger" role="alert">
                        <b>Presupuesto no válido.</b> Seguramente te falte asignar algún envio o añadir el bastidor del vehículo.
                    </div>
                }

                {presupuestoId > 0 ? (
                    <button
                        type="submit"
                        id="submit"
                        className="btn btn-primary"
                    >
                        Actualizar
                    </button>
                ) : (
                    <button
                        type="submit"
                        disabled={isDisabledCreateNew || isLoading}
                        className="btn btn-primary"
                    >
                        { (isLoading) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" className="px-5" spin />
                            :
                            'Crear Presupuesto'
                        }
                    </button>
                )}
            </div>
        </form>
    )
}