import { useState, useEffect } from 'react';
import { Box, Switch, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';

export default function EditarVentaModal({ venta, piezas, onEditarVenta }) {
  const [uniquePiezas, setUniquePiezas] = useState(new Map());
  const [uniqueEnvios, setUniqueEnvios] = useState(new Map());
  const [listaPiezas, setListaPiezas] = useState([]);
  const [changeTransportista, setChangeTransportista] = useState(false);
  const [tab, setTab] = useState('confirmar-piezas');
  const handleChangeTab = (_e, tab) => setTab(tab);

  const transportistas = useSelector(state => {
    return state.transportistas
  });
  const serviciosEnvio = useSelector(state => {
    return state.servicios_envio;
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset
  } = useForm();

  useEffect(() => {
    const enviosData = {};

    venta.envios?.forEach((envio) => {
      enviosData[envio.id] = {
        url_seguimiento: envio.url_seguimiento || '',
        numero_seguimiento: envio.numero_seguimiento === '-' ? '' : envio.numero_seguimiento,
        codigo_transporte: envio.codigo_transporte,
        transportista_id: envio.transportista_id,
        servicio_envio_id: envio.servicio_envio_id,
      };
    });

    reset({ envios: enviosData });
  }, [venta.envios, reset]);

  useEffect(() => {
    const newUniquePiezas = new Map(uniquePiezas);
    const newUniqueEnvios = new Map(uniqueEnvios);

    piezas.forEach((pieza) => {
      newUniquePiezas.set(pieza.id, {
        piezaId: pieza.id,
        idReferenciaPlataforma: pieza.id_referencia_plataforma,
        ventaId: venta.id,
        confirmacion: pieza.confirmacion,
        proveedorId: pieza.proveedor?.id,
      });
    });

    venta.envios?.forEach((envio) => {
      newUniqueEnvios.set(envio.id, {
        id: envio.id,
        codigo_transporte: envio.codigo_transporte,
        url_seguimiento: envio.url_seguimiento,
        numero_seguimiento: envio.numero_seguimiento,
        transportista_id: envio.transportista_id,
        servicio_envio_id: envio.servicio_envio_id,
      });
    });

    setUniquePiezas(newUniquePiezas);
    setUniqueEnvios(newUniqueEnvios);

    setListaPiezas(Array.from(newUniquePiezas.values()));
  }, [piezas, venta.id]);

  const handleConfirmPartsModal = (data) => {
    const changedPiezas = listaPiezas.filter((pieza) => {
      const original = uniquePiezas.get(pieza.piezaId);
      return (
        original &&
        (original.confirmacion !== pieza.confirmacion)
      );
    });
    const changedEnvios = Object.entries(data.envios)
      .filter(([id, envio]) => {
        const original = uniqueEnvios.get(parseInt(id));
        if (!original) return false;
        const url = envio.url_seguimiento === "" ? null : envio.url_seguimiento;
        const numSeguimiento = envio.numero_seguimiento === "" ? '-' : envio.numero_seguimiento;

        return (
          (original.url_seguimiento !== url) ||
          (original.numero_seguimiento !== numSeguimiento) ||
          (original.transportista_id !== envio.transportista_id) ||
          (original.servicio_envio_id !== envio.servicio_envio_id)
        );
      })
      .map(([id, envio]) => ({
        id: parseInt(id),
        ...envio
      }));
    if (changedPiezas.length > 0 || changedEnvios.length > 0) {
      onEditarVenta(changedPiezas, changedEnvios);
    } else return;
  };

  const onChangePartConfirmation = (state, piezaId) => {
    console.log(errors);
    setValue(`checkbox-${piezaId}`, state);
    const updatedPiezas = listaPiezas.map((pieza) => {
      if (pieza.piezaId === piezaId) {
        return {
          ...pieza,
          confirmacion: state,
        };
      }
      return pieza;
    });
    setListaPiezas(updatedPiezas);
  };

  return (
    <form onSubmit={handleSubmit(handleConfirmPartsModal)}>
      <div className="d-flex flex-column">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Mi Cuenta">
              <Tab label="Confirmar piezas" value="confirmar-piezas" />
              <Tab label="Seguimiento del pedido" value="seguimiento-pedido" />
            </TabList>
          </Box>
          <TabPanel value="confirmar-piezas">
            <div className="mb-4 d-flex flex-column gap-3 w-100">
              {piezas?.map((pieza) => (
                <div key={pieza.id} className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="ms-0"
                    disabled={!pieza.proveedor.credito}
                    defaultChecked={pieza.confirmacion}
                    id={`checkbox-${pieza.id}`}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => onChangePartConfirmation(e.target.checked, pieza.id)}
                  />
                  <label htmlFor={`checkbox-${pieza.id}`} className="ms-2 fs-6 d-flex align-items-center" style={{ cursor: "pointer" }}>
                    <span>[ID: <strong>{pieza.id}</strong>]</span>
                    <span
                      className="text-truncate"
                      style={{
                        display: "inline-block",
                        maxWidth: "400px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        verticalAlign: "middle",
                        margin: "0 0 0 .5rem",
                      }}
                      title={pieza.nombre}
                    >
                      {pieza.nombre}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value="seguimiento-pedido">
            {venta?.envios?.map((envio) => (
              <Accordion key={envio.id}>
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="mejoras-metricas"
                >
                  <p className={"fw-bold " + (envio.numero_seguimiento !== '-' ? 'text-green' : 'text-danger')}>{envio.codigo_transporte}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-100 d-flex flex-column">
                    <div className="w-100 d-flex align-items-center">
                      <Switch 
                        color="success"
                        defaultChecked={changeTransportista}
                        onChange={(e) => setChangeTransportista(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }} /> 
                      <label>Cambio de transportista?</label>
                    </div>
                    
                      <div>
                        <div className="w-100">
                          {envio.transportista_id === 1 && (
                            <>
                              <div className="form-group mb-0">
                                <label htmlFor="url_seguimiento">URL de seguimiento:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`envios.${envio.id}.url_seguimiento`)}
                                />
                              </div>
                            
                              <div className="form-invalid">
                                {errors.envios?.[envio.id]?.url_seguimiento && 'La URL de seguimiento es obligatoria'}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="w-100">
                          <div className="form-group mb-0 mt-3">
                            <label htmlFor="numero_seguimiento">Número de seguimiento:</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`envios.${envio.id}.numero_seguimiento`)}
                            />
                          </div>
                          <div className="form-invalid">
                            {errors.envios?.[envio.id]?.numero_seguimiento && 'El número de seguimiento es obligatorio'}
                          </div>
                        </div>
                      </div>

                    { (changeTransportista) &&
                      <div className='mt-4'>
                        <div className="form-group">
                          <label>Nuevo transportista:</label>
                          <select 
                              id={`envios.${envio.id}.transportista_id`}
                              defaultValue={envio.transportista_id}
                              {...register(`envios.${envio.id}.transportista_id`, { required: true })}
                              onChange={(e) => setValue(`envios.${envio.id}.transportista_id`, e.target.value)}>
                              {transportistas?.map(op => <option key={'transportista-' + op.id} value={op.id}>{op.nombre}</option>)}
                          </select>
                        </div>
                        { watch(`envios.${envio.id}.transportista_id`) &&
                        <div className="form-group">
                          <label>Nuevo servicio de envío:</label>
                          <select 
                              id={`envios.${envio.id}.servicio_envio_id`}
                              defaultValue={envio.servicio_envio_id}
                              {...register(`envios.${envio.id}.servicio_envio_id`, { required: true })}
                              onChange={(e) => setValue(`envios.${envio.id}.servicio_envio_id`, e.target.value)}>
                              {serviciosEnvio.filter(sv => sv.transportista_id == getValues(`envios.${envio.id}.transportista_id`))
                                ?.map(op => <option key={'servicioenvio-' + op.id} value={op.id}>{op.nombre}</option>)}
                          </select>
                        </div>
                        }
                      </div>
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        </TabContext>

        <button type="submit" className="btn btn-primary align-self-end">
          Confirmar
        </button>
      </div>
    </form>
  );
}