import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { newTarifa, updateTarifa } from "../../../../../shared/services/tarifas";

export default function TarifasForm({tarifa, isEdit, onCancel, onActionDone}) {
    const dispatch = useDispatch();
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: 0,
            nombre: '',
            default: false,
            categoria: '',
            tipo_cliente_id: 1
        }
    });

    useEffect(() => {
        reset(tarifa);
    }, [tarifa]);

    const handleChangeTipoClientes = (e) => { setValue('tipo_cliente_id', parseInt(e.target.value)); }
    const handleChangeCategoria = (e) => { setValue('categoria', parseInt(e.target.value)); }

    const handleSubmitTarifa = async(data, e) => {
        e.preventDefault();
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(data.categoria == -1) {
            data.categoria = null;
        } else { data.categoria = 'cuenta-grande'; }
        
        if(isEdit) {
            response = await updateTarifa(data);
        } else {
            response = await newTarifa(data);
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            onActionDone();
        }
    }

    const handleCancel = () => onCancel('tarifa');
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form flex-column align-items-center mb-4" onSubmit={handleSubmit(handleSubmitTarifa)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={isEdit ? tarifa.id : ''} />

                <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("nombre", { required: true })} 
                        defaultValue={isEdit ? tarifa.nombre : 0} />
                    
                    <div className="form-invalid">
                        {errors.nombre?.type === 'required' && "Nombre es obligatorio"}
                    </div>
                </div>

                <div className="form-group flex-row align-items-center">
                    <label className="m-0 me-3">Por defecto:</label>
                    <input 
                        type="checkbox"
                        {...register("default")} />
                </div>

                <div className="form-group">
                    <label htmlFor="tipo_cliente_id">Tipo cliente</label>
                    <select 
                        {...register("tipo_cliente_id", {required: true})}
                        defaultValue={isEdit ? tarifa.tipo_cliente_id : 1}
                        onChange={handleChangeTipoClientes}>
                        {tipoClientes?.map(op => <option key={'tipo-clientes-' + op.id} value={op.id}>{op.nombre}</option>)}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="categoria">Categoria</label>
                    { (tipoClientes && tipoClientes.length > 0) &&
                        <select 
                            {...register("categoria")}
                            defaultValue={isEdit ? (tarifa.categoria === 'cuenta-grande' ? 1 : -1) : -1}
                            onChange={handleChangeCategoria}>
                            <option value={-1}>Ninguna</option>
                            <option value={1}>Grandes cuentas</option>
                        </select>
                    }
                </div>

                <div className="d-flex align-items-center align-self-end ms-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    <button type="button" className="btn btn-default align-self-end ms-3" onClick={handleCancel}>Cancelar</button>
                </div>
            </form>
        </div>
    )
}