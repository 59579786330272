import React from "react";
import { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { enviarNotificacionResponsable } from "../../../../../../../../../../../shared/services/presupuestos";
import { toast } from "react-toastify";

export default function ProveedorPocoFiableModal({ 
    pieza,
    state,
    onConfirmAction,
    onClose
}) {
    const [open, setOpen] = useState(state);
    const [motivo, setMotivo] = useState(-1);
    const [observaciones, setObservaciones] = useState("");
    const motivos = [
        "Solo hay una en el mercado", 
        "Tengo que cubrir un fallo de stock o garantía",
        "Es una pieza que por su naturaleza no puede generar incidencia",
        "Otros" 
    ];

    const handleConfirm = async(confirm) => {
        setOpen(false);

        if(confirm) {
            const response = await enviarNotificacionResponsable({
                validacion: motivos[motivo], 
                observaciones: observaciones,
                pieza: pieza.nombre + ' ID #' + (pieza?.id_referencia_plataforma ? pieza?.id_referencia_plataforma : '-'
            )});
            toast.success(response.message);
        }

        onConfirmAction(confirm);
    };

    const handleClose = (event, reason) => { 
        if (reason && reason === "backdropClick") return;

        setOpen(false); 
        onClose();
    };

    const handleChangeMotivo = (e) => setMotivo(parseInt(e.target.value));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description">
            <DialogTitle id="confirm-title">
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className="fw-bold text-uppercase">Proveedor NO FIABLE</p>

                    <span title="cerrar" className="action" onClick={() => handleClose()}>
                        <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#215732" />
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-description">
                    <p>Estás seguro de añadir la pieza de un proveedor NO fiable?</p>
                </DialogContentText>

                <div className="w-100 d-flex justify-content-center mt-3">
                    <Select
                        size="small"
                        name="motivo"
                        id="motivo"
                        onChange={handleChangeMotivo}
                        value={motivo}>
                            <MenuItem 
                                key={'motivo--1'}
                                value={-1}>
                                {"Selecciona una opción"}
                            </MenuItem>
                            { motivos?.map(((m, i) => {
                                return (
                                    <MenuItem 
                                        key={'motivo-' + i}
                                        value={i}>
                                        {m}
                                    </MenuItem>
                                )
                            }))}
                    </Select>
                </div>

                <div className="w-100">
                    { (motivos[motivo] === 'Otros') ?
                        <div className="form-group">
                            <label htmlFor="observaciones">Motivo</label>
                            <textarea
                                name="observaciones"
                                defaultValue={observaciones}
                                onChange={(e) => setObservaciones(e.target.value)}
                                rows={2}
                            ></textarea>
                        </div>
                        : ''
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-primary" disabled={motivo === -1} onClick={() => handleConfirm(true)}>Si</button>
                <button type="button" className="btn btn-default" disabled={motivo === -1} onClick={() => handleConfirm(false)}>No</button>
            </DialogActions>
        </Dialog>
    );
}