import { useState, useEffect } from "react";
import React from "react";
import ClientesSearcher from "./components/ClientesSearcher";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { newClientePresupuesto, newDireccionClientePresupuesto, updateClientePresupuesto, updateDireccionClientePresupuesto } from "../../../../../../../../../../redux/features/fichaPresupuesto";
import ClienteChangeModal from "../../../../../../../shared/components/ClienteChangeModal";
import ClienteModal from "../../../../../../../shared/components/ClienteModal";


export default function ClienteWizard({
    presupuestoId,
    cliente,
    selectedDireccionId,
    isEdit,
    isChange,
    onSelectedCliente,
    onClienteChange,
    onSelectedDireccion,
    onCancelEditCliente,
    onCancelChangeCliente,
}) {
    const dispatch = useDispatch();
    const [currentCliente, setCurrentCliente] = useState(cliente);
    const [telefono, setTelefono] = useState(null);
    const [isClientSelected, setIsClientSelected] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openChangeForm, setOpenChangeForm] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if(isEdit) { setOpenForm(true); }
        else { setOpenForm(false); }

        if(isChange) { setOpenChangeForm(true); }
        else { setOpenChangeForm(false); }

        if(cliente) { setIsClientSelected(true); setCurrentCliente(cliente); }
        else { setIsClientSelected(false); setCurrentCliente(null); }

    }, [isEdit, isChange, cliente]);

    const handleSetTelefono = (telefono) => { setTelefono(telefono); }

    const handleSubmitClient = async (data) => {
        dispatch({type: 'loading/set', payload: true});

        if(isEdit) {
            dispatch(updateClientePresupuesto(data))
        } else {
            dispatch(newClientePresupuesto(data, isChange, presupuestoId));
        }

        if(isChange) { onCancelChangeCliente(); }
        else { onSelectedCliente(); }
    };

    const handleSubmitDireccion = async(isEdit, direccionData) => {
        dispatch({type: 'loading/set', payload: true});
        if(isEdit) dispatch(updateDireccionClientePresupuesto(direccionData)); 
        else dispatch(newDireccionClientePresupuesto(direccionData));
    }

    const handleSelectDireccion = (direccion) => { onSelectedDireccion(direccion); }

    const handleSetCliente = (cliente) => {
        setRefresh(false);
        
        if(cliente) {
            if(cliente.bloqueado) {
                toast.error('Error! Cliente bloqueado.');
                setIsClientSelected(false);
            } else onSelectedCliente(cliente);
        } else setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false); 
        setRefresh(true);
        setOpenChangeForm(false);
        onCancelEditCliente(); 
    }
    const handleCloseChangeForm = () => { 
        setOpenForm(false);
        setRefresh(true);
        setOpenChangeForm(false); 
        onCancelChangeCliente(); 
    }

    const handleNewCliente = () => {
        setOpenChangeForm(false);
        setRefresh(false);
        setOpenForm(true);
        setCurrentCliente(null);
    }

    return (
        <div>
            { (presupuestoId === 0) &&
                <div>
                    { !isClientSelected &&
                        <div>
                            <ClientesSearcher
                                refresh={refresh}
                                onResult={handleSetCliente}
                                onSetTelefono={handleSetTelefono}
                            />             
                        </div>
                    }
                </div>
            }

            { (openForm) &&
                <ClienteModal
                    presupuestoId={presupuestoId} 
                    fromPresupuesto={true} 
                    open={openForm}
                    cliente={currentCliente}
                    telefono={telefono}
                    isEdit={isEdit}
                    isChange={isChange}
                    selectedDireccionId={selectedDireccionId}
                    onSubmitCliente={handleSubmitClient}
                    onSubmitDireccion={handleSubmitDireccion}
                    onSelectedDireccion={handleSelectDireccion}
                    onClose={handleCloseForm}
                />
            }

            { (openChangeForm) &&
                <ClienteChangeModal 
                    open={openChangeForm}
                    title={"Cambiar cliente"}
                    fromPresupuesto={true}
                    presupuestoClient={cliente}
                    onClienteChange={onClienteChange}
                    onNewCliente={handleNewCliente}
                    onClose={handleCloseChangeForm}
                />
            }
        </div>
    )
}