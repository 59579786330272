import { useForm } from "react-hook-form";
import { getAllowedExt } from "../../../shared/helpers/functionalities";

export default function MultiFileUploadForm({ onSubmit, itemId, labelText }) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            id: itemId,
            files: '',
        }
    });
    
    const handleSubmitPhotos = async (data, e) => {
        e.preventDefault();
        onSubmit(data);
    };
    
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <div>
                <form className="form  mb-4" onSubmit={handleSubmit(handleSubmitPhotos)}>
                    <input
                        type="hidden"
                        {...register("id")}
                        defaultValue={itemId}
                    />
                    <div className="w-100 d-flex">
                        <div className="form-group">
                            <label htmlFor="files">{labelText}</label>
                            <input
                                type="file"
                                multiple
                                className="form-control"
                                accept={getAllowedExt('document')}
                                {...register("files")}
                                defaultValue={''}
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center align-self-end">
                        <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}