import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './pendientesList.scss';
import { fetchVentasPendientes } from '../../../../../../redux/ventasPendientes';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Autocomplete, TextField } from '@mui/material';

export default function PendientesList() {
    const user = JSON.parse(localStorage.getItem('user'));
    const pendientes = useSelector((state) => {
        return state.ventas_pendientes;
    });
    const [isOpen, setIsOpen] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState('Todos');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contentRef = useRef(null);

    const handleGoToVenta = (numero) => {
        dispatch({
            type: 'search/set',
            payload: { search: numero, key: 'presupuesto' },
        });
        navigate('/ventas/1', { replace: true });
    };

    const handleFilterByUsername = (username) => {
        debugger
        setSelectedUser(username);
        if(username === 'Todos') return setFilteredResults(pendientes);
        const filteredArr = pendientes.filter(pendiente => (pendiente.created_by.username).includes(username));
        setFilteredResults(filteredArr);
    }

    useEffect(() => {
        dispatch(fetchVentasPendientes());
        
        //se hará la petición cada media hora
        const interval = setInterval(() => {
            dispatch(fetchVentasPendientes());
        }, 1800000);

        return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
        setFilteredResults(pendientes);
    }, [pendientes])

    useEffect(() => {
        if (isOpen && contentRef.current) {
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        }
    }, [filteredResults, isOpen]);

    return (
        <>
            {pendientes && pendientes.length > 0 && (
                <div className="pendientes-list">
                    <div
                        className={`pendientes-list-item ${
                            isOpen ? 'active' : ''
                        }`}
                    >
                        <div
                            className="pendientes-list-header"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Proformas pendientes: {pendientes?.length}
                            <span className={`icon ${isOpen ? 'rotate' : ''}`}>
                                <ArrowDropDownIcon />
                            </span>
                        </div>
                        <div
                            ref={contentRef}
                            className="pendientes-list-content"
                            style={{
                                maxHeight: isOpen
                                    ? `${contentRef.current?.scrollHeight}px`
                                    : '0px',
                                opacity: isOpen ? 1 : 0,
                            }}
                        >
                            {user?.responsable && (
                                <Autocomplete
                                    className='mb-3 autocomplete-pendientes-list'
                                    id="steps-responsable"
                                    options={['Todos',...new Set(pendientes.map(pendiente => pendiente.created_by.username))]}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newValue) => {
                                        handleFilterByUsername(newValue);
                                    }}
                                    value={selectedUser}
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField {...params} label="Responsable" />
                                    )}
                                />
                            )}
                            {filteredResults?.map((pendiente) => {
                                return (
                                    <div
                                        key={`'pendiente-${pendiente.id}`}
                                        className="alert alert-warning"
                                    >
                                        <div className="w-100 d-flex">
                                            <div className="d-flex flex-grow-1">
                                                <div className="d-flex align-items-center">
                                                    <span className="me-2">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faExclamationTriangle
                                                            }
                                                            size="1x"
                                                            color="#ffc107"
                                                        />
                                                    </span>
                                                    <small
                                                        role="button"
                                                        className="fw-bold"
                                                        onClick={() =>
                                                            handleGoToVenta(
                                                                pendiente
                                                                    .presupuesto
                                                                    .numero
                                                            )
                                                        }
                                                    >
                                                        {
                                                            pendiente
                                                                .presupuesto
                                                                .numero
                                                        }
                                                    </small>
                                                    <span className="mx-2">
                                                        |
                                                    </span>
                                                    <small>
                                                        {moment(
                                                            pendiente.fecha
                                                        ).format('DD-MM-YYYY')}
                                                    </small>
                                                    {user?.responsable ? (
                                                        <>
                                                            <span className="mx-2">
                                                                |
                                                            </span>
                                                            <small className="fw-bold text-uppercase">
                                                                {
                                                                    pendiente
                                                                        .created_by
                                                                        .username
                                                                }
                                                            </small>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>

                                                <div className="d-flex flex-column flex-grow-1 pe-2">
                                                    <ul className="m-0">
                                                        {pendiente?.piezas
                                                            ?.filter((p) => {
                                                                if (
                                                                    p.proveedor_id !==
                                                                    409
                                                                )
                                                                    return true;
                                                                else
                                                                    return (
                                                                        p.servicio_envio ===
                                                                        1
                                                                    );
                                                            })
                                                            .map((pieza) => {
                                                                return (
                                                                    <li
                                                                        key={`'pieza-pendiente-${pieza.id}`}
                                                                        style={{
                                                                            listStyle:
                                                                                'square',
                                                                        }}
                                                                    >
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="d-flex">
                                                                                <small className="text-uppercase">
                                                                                    {
                                                                                        pieza.nombre
                                                                                    }
                                                                                </small>

                                                                                <div className="d-flex align-items-center ms-3">
                                                                                    <small className="text-uppercase fw-bold me-2">
                                                                                        {
                                                                                            pieza
                                                                                                .proveedor
                                                                                                .nombre
                                                                                        }
                                                                                    </small>
                                                                                    <small>
                                                                                        {
                                                                                            pieza
                                                                                                .proveedor
                                                                                                .telefono
                                                                                        }
                                                                                    </small>
                                                                                    {pieza
                                                                                        .proveedor
                                                                                        .telefono_recambios && (
                                                                                        <>
                                                                                            <span className="mx-2">
                                                                                                -
                                                                                            </span>
                                                                                            <small>
                                                                                                Recambios:{' '}
                                                                                                {
                                                                                                    pieza
                                                                                                        .proveedor
                                                                                                        .persona_recambios
                                                                                                }{' '}
                                                                                                {
                                                                                                    pieza
                                                                                                        .proveedor
                                                                                                        .telefono_recambios
                                                                                                }
                                                                                            </small>
                                                                                        </>
                                                                                    )}
                                                                                    {pieza
                                                                                        .proveedor
                                                                                        .vales
                                                                                        ?.length >
                                                                                        0 &&
                                                                                    pieza.proveedor.vales.find(
                                                                                        (
                                                                                            v
                                                                                        ) =>
                                                                                            v.active
                                                                                    ) ? (
                                                                                        <>
                                                                                            <span className="mx-2"></span>
                                                                                            <span className="fw-bold">
                                                                                                Proveedor
                                                                                                con
                                                                                                VALE
                                                                                                pendiente
                                                                                            </span>
                                                                                        </>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>

                                                                            {pieza
                                                                                .proveedor
                                                                                .id ===
                                                                            409 ? (
                                                                                <div className="ms-2">
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faExclamationTriangle
                                                                                        }
                                                                                        size="lg"
                                                                                        color="#ffc107"
                                                                                    />
                                                                                    <span className="fw-bold mx-1">
                                                                                        Pendiente
                                                                                        de
                                                                                        facilitar
                                                                                        etiqueta
                                                                                        a
                                                                                        almacén.
                                                                                    </span>
                                                                                    <FontAwesomeIcon
                                                                                        icon={
                                                                                            faExclamationTriangle
                                                                                        }
                                                                                        size="lg"
                                                                                        color="#ffc107"
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <p className="align-self-end">
                                                Pendiente de confirmación
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
