import { useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";

export default function ObservacionesForm({
    onSubmitObservacion,
    entityId
}) {

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            entity_id: entityId,
            observaciones: '',
        }
    });
    const [defaultOpt, setDefaultOpt] = useState(-1);
    const options = [
        "Llamar en 30min",
        "Llamar en 1h",
        "Llamar a la tarde",
        "Llamar mañana",
        'Llamar lunes',
        'Llamar martes',
        'Llamar miercoles',
        'Llamar jueves',
        'Llamar viernes',
        'Sólo enviar whatsapp',
    ];
    
    const handleChangeObservaciones = (e) => {
        const value = parseInt(e.target.value);
        setDefaultOpt(value);

        const obesrvaciones = value !== -1 && options?.[value] ? options[value] : '';
        setValue('observaciones', obesrvaciones);
    }


    const handleSubmitVenta = async (data, e) => {
        e.preventDefault();
        data.observaciones = data.observaciones === '' ? null : data.observaciones;
        onSubmitObservacion(data);
    };
 
    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="form" onSubmit={handleSubmit(handleSubmitVenta)}>
                <input 
                    type="hidden" 
                    {...register("entity_id")} 
                    defaultValue={entityId} />

                <div className="w-100">
                    <div className="form-group flex-grow-1">
                        <select
                            value={defaultOpt}
                            onChange={handleChangeObservaciones}
                        >
                            <option key={'opt--1'} value={-1}></option>
                            {options?.map((opt, i) => <option key={'opt-' + i} value={i}>{opt}</option>)}
                        </select>
                    </div>
                </div>
                    
                <div className="d-flex align-items-center align-self-end mt-3">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    );
}