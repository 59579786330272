import FilesGrid from "../../../../../shared/components/FilesGrid";
import MultiFileUploadForm from "../../../../../shared/components/MultiFileUploadForm";
import { Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from "react";

export default function DocumentosModal({ currentIncidencia, incidenciaId, onDeleteFile, onDownload, onUploadFiles }) {
  /** Variables */
  const [tab, setTab] = useState('visualizar');

  /** Render */
  return (
    <div>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(_e, tab) => setTab(tab)} aria-label="Documentos">
            <Tab label="Visualizar" value="visualizar" />
            <Tab label="Subir documentos" value="subir" />
          </TabList>
        </Box>
        <TabPanel value="visualizar">
          <FilesGrid
            filesList={currentIncidencia?.documentacion}
            incidenciaId={incidenciaId}
            canDelete={true}
            onDelete={onDeleteFile}
            onDownload={onDownload}
          />
        </TabPanel>
        <TabPanel value="subir">
          <MultiFileUploadForm
            onSubmit={onUploadFiles}
            itemId={incidenciaId}
            labelText={"Subir documentos"}
          />
        </TabPanel>
      </TabContext>
    </div>
  )
}