import API from '../../../../../services/api';

export const getReadOnlyData = async () => {
    return await API.get('/dashboard/read-only').then(res => res.data);
}

// ----------------------- ADMIN ----------------------- //
export const getAdminData = async () => {
    return await API.get('/dashboard/admin').then(res => res.data);
}

export const refreshAdminData = async () => {
    return await API.get('/dashboard/admin-refresh').then(res => res.data);
}

export const getAdminAtcData = async () => {
    return await API.get('/dashboard/admin-atc').then(res => res.data);
}

export const getUserMetrics = async (userId, startDate, endDate, view) => {
    const params = '?user_id= ' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/user-metrics' + params).then(res => res.data);
}

export const getGeneralMetrics = async (startDate, endDate, view, clean_cache = false) => {
    let params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    if(clean_cache) params += `&clean_cache=${true}`;
    return await API.get('/dashboard/admin/general-metrics' + params).then(res => res.data);
}

export const getUserAtcMetrics = async (userId, startDate, endDate, view, tipo, culpa) => {
    const params = '?user_id= ' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&view=' + view + ((tipo && tipo !== 'todos') ? ('&tipo=' + tipo) : '') + ((culpa && culpa !== 'todos') ? ('&culpa=' + culpa) : '');
    return await API.get('/dashboard/admin/user-atc-metrics' + params).then(res => res.data);
}

export const getGeneralAtcMetrics = async (startDate, endDate, view, tipo, culpa, userId) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view + ((tipo && tipo !== 'todos') ? ('&tipo=' + tipo) : '') + ((culpa && culpa !== 'todos') ? ('&culpa=' + culpa) : '') + ((userId) ? ('&user_id=' + userId) : '');
    return await API.get('/dashboard/admin/general-atc-metrics' + params).then(res => res.data);
}

export const getClientesMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/clientes-metrics' + params).then(res => res.data);
}

export const getProveedoresMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/proveedores-metrics' + params).then(res => res.data);
}

export const getPiezasMetrics = async (startDate, endDate, view) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view;
    return await API.get('/dashboard/admin/piezas-metrics' + params).then(res => res.data);
}

export const getIncidenciasMetrics = async (resp, startDate, endDate, view, proveedorId, tipoPiezaId, culpa) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view + (resp ? ('&resp_id=' + resp) : '')
    + (proveedorId ? ('&proveedor_id=' + proveedorId) : '') + (tipoPiezaId ? ('&tipo_pieza_id=' + tipoPiezaId) : '') +
    + (culpa ? ('&culpa=' + culpa) : '');
    return await API.get('/dashboard/admin/incidencias-metrics' + params).then(res => res.data);
}

export const getProveedorIncidenciasMetrics = async (startDate, endDate, view, proveedorId) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&view=' + view
    + (proveedorId ? ('&proveedor_id=' + proveedorId) : '');
    return await API.get('/dashboard/admin/proveedores-incidencias-metrics' + params).then(res => res.data);
}

export const filterProveedorIncidenciasMetrics = async (startDate, endDate, type, culpa, tipo) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate + '&type=' + type
    + (culpa ? ('&culpa=' + culpa) : '') + (tipo ? ('&tipo=' + tipo) : '');
    return await API.get('/dashboard/admin/filter-proveedores-incidencias-metrics' + params).then(res => res.data);
}

export const getTransferencias = async () => {
    return await API.get('/dashboard/admin/transferencias').then(res => res.data);
}

export const getComprobanteTransferencia = async (comprobante) => {
    return await API.get('/dashboard/admin/comprobante/' + comprobante, {responseType: 'blob'}).then(res => res.data);
}

// ----------------------- USER ----------------------- //

export const getRecambistasMessages = async () => {
    return await API.get('/dashboard/user/messages').then(res => res.data);
}

export const getUserResume = async (startDate, endDate) => {
    const params = '?startDate=' + startDate + '&endDate=' + endDate;
    return await API.get('/dashboard/user/resumen' + params).then(res => res.data);
}

export const getDynamicDashboard = async (startDate, endDate, userId) => {
    let params = '';

    if(startDate && endDate && userId) {
        params = '?startDate=' + startDate + '&endDate=' + endDate + '&user_id=' + userId;
    }  
    return await API.get('/dashboard/user/dynamic' + params).then(res => res.data);
}